<form autocomplete="off">
  <input
    *appIfIsMobile
    [ngbTypeahead]="searchRest"
    [ngClass]="inputClass"
    type="text"
    class="form-control"
    [placement]="'bottom-left'"
    [popupClass]="'search-city-popup search-city-popup-mobile'"
    [focusFirst]="false"
    [editable]="false"
    id="restaurant-input"
    autocomplete="new-search-city"
    placeholder="       Restaurant name"
  >
</form>
